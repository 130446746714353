<div class="container">
    <div class="row">
        <div class="col-12 py-3">
            <div class="background-pink py-5 px-3 p-lg-5">
                <p class="text-size-s color-white pb-4">Das Wichtigste im Überblick</p>
                <ul>
                    @for (tableOfContentItem of body.items; track tableOfContentItem) {
                    <li class="mb-5">
                        <a
                            class="text-decoration-underline"
                            [title]="tableOfContentItem.title"
                            [routerLink]="currentPath"
                            [fragment]="tableOfContentItem.anker"
                            >{{ tableOfContentItem.title }}</a
                        >
                    </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</div>
