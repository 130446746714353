import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ImageField, KeyTextField, LinkField, RichTextField, Slice, TitleField } from '@prismicio/client';
import { HTMLMapSerializer } from '@prismicio/helpers';
import { AosDirective } from '../../aos/aos.directive';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-contact-details-slice',
    templateUrl: './contact-details-slice.component.html',
    styleUrls: ['./contact-details-slice.component.scss'],
    standalone: true,
    imports: [AosDirective, LinkComponent, RenderHtmlPipe]
})
export class ContactDetailsSliceComponent implements OnInit {
    @Input() body!: ContactDetailsSlice;
    formGroup?: FormGroup;

    constructor() {}

    ngOnInit(): void {}

    htmlSerializer: HTMLMapSerializer = {
        heading3: ({ children }) => '<h3 class="color-white text-size-l">' + children + '</h3>'
    };
}

export type ContactDetailsSlice = Slice<
    'contact_details',
    {
        title: TitleField;
        description: RichTextField;
        image: ImageField;
        image_caption: KeyTextField;
    },
    ContactAction
>;

export type ContactAction = {
    button_title: KeyTextField;
    button_link: LinkField;
};
