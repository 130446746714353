import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, Slice } from '@prismicio/client';

@Component({
    selector: 'app-anker-slice',
    templateUrl: './anker-slice.component.html',
    styleUrls: ['./anker-slice.component.scss'],
    standalone: true
})
export class AnkerSliceComponent implements OnInit {
    @Input() body!: AnkerSlice;

    constructor() {}

    ngOnInit(): void {}
}

export type AnkerSlice = Slice<
    'anker',
    {
        anker: KeyTextField;
    },
    never
>;
