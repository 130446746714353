import { Component, Input, OnInit } from '@angular/core';
import { KeyTextField, LinkField, NumberField, RichTextField, SelectField, Slice } from '@prismicio/client';
import { LinkComponent } from '../../link/link.component';
import { RenderHtmlPipe } from '../render-html/render-html.pipe';

@Component({
    selector: 'app-quiz-slice',
    templateUrl: './quiz-slice.component.html',
    styleUrls: ['./quiz-slice.component.scss'],
    standalone: true,
    imports: [LinkComponent, RenderHtmlPipe]
})
export class QuizSliceComponent implements OnInit {
    @Input() body!: QuizSlice;

    showResults = false;

    currentQuizItem?: QuizItem;
    currentQuizItemIndex = 0;

    totalScore = 0;

    answers: Answer[] = [
        {
            emoji: '🤩',
            description: 'Sehr groß',
            weight: 3
        },
        {
            emoji: '🙂',
            description: 'Groß',
            weight: 2
        },
        {
            emoji: '🤨',
            description: 'Niedrig',
            weight: 1
        },
        {
            emoji: '😏',
            description: 'Sehr niedrig',
            weight: 0
        }
    ];

    constructor() {}

    ngOnInit(): void {
        if (this.body.items.length > 0) {
            this.currentQuizItem = this.body.items[0];
        }
    }

    continue(answer: Answer) {
        this.totalScore += answer.weight;
        this.currentQuizItemIndex += 1;

        if (this.currentQuizItemIndex < this.body.items.length) {
            this.currentQuizItem = this.body.items[this.currentQuizItemIndex];

            return;
        }

        this.showResults = true;
    }
}

export type QuizSlice = Slice<
    'quiz',
    {
        passed_title: KeyTextField;
        passed_description: RichTextField;
        passed_button_title: KeyTextField;
        passed_button_description: RichTextField;
        passed_button_link: LinkField;
        passed_button_action: SelectField;
        failed_title: KeyTextField;
        failed_description: RichTextField;
        score_limit: NumberField;
    },
    QuizItem
>;

export type QuizItem = {
    question: KeyTextField;
};

export type Answer = {
    emoji: string;
    description: string;
    weight: number;
};
