<div class="container py-5">
    <div class="row">
        <div class="col-12">
            <div class="background-pink px-4 py-5 p-lg-5">
                @if (showResults) { @if (totalScore > body.primary.score_limit) {
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-8 text-center">
                        <p class="text-size-xxl">🤩</p>
                        <p class="text-size-l color-white">{{ body.primary.passed_title }}</p>
                        <div [innerHTML]="body?.primary.passed_description | renderHtml"></div>
                        <app-link
                            [link]="body.primary.passed_button_link"
                            [action]="body.primary.passed_button_action"
                            [cssClass]="'btn btn-black mt-4'"
                        >
                            {{ body.primary.passed_button_title }}
                        </app-link>
                    </div>
                </div>
                } @else {
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-8 text-center">
                        <p class="text-size-xxl">😔</p>
                        <p class="text-size-l color-white">{{ body.primary.failed_title }}</p>
                        <div [innerHTML]="body?.primary.failed_description | renderHtml"></div>
                    </div>
                </div>
                } } @else {
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-8 text-center">
                        <p class="heading color-white">Frage {{ currentQuizItemIndex + 1 }} von {{ body.items.length }}</p>
                        <p class="text-size-l color-white">{{ currentQuizItem?.question }}</p>
                    </div>
                </div>
                <div class="row my-lg-5">
                    @for (answer of answers; track answer) {
                    <div class="col-lg-3 mt-4">
                        <button class="button-unstyled color-black w-100" (click)="continue(answer)">
                            <div class="answer d-flex flex-lg-column align-items-center p-4 py-lg-5 text-center">
                                <p class="text-size-xl pe-4 pe-lg-0 mb-0 mb-lg-4">{{ answer.emoji }}</p>
                                <p class="mb-0">{{ answer.description }}</p>
                            </div>
                        </button>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    </div>
</div>
