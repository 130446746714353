import { Component, inject, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { KeyTextField, Slice } from '@prismicio/client';

@Component({
    selector: 'app-table-of-content-slice',
    templateUrl: './table-of-content-slice.component.html',
    styleUrls: ['./table-of-content-slice.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class TableOfContentSliceComponent implements OnInit {
    @Input() body!: TableOfContentSlice;
    private router = inject(Router);
    currentPath?: string;

    constructor() {}

    ngOnInit(): void {
        this.currentPath = this.router.url;
        this.router.events.subscribe(() => {
            this.currentPath = this.router.url;
        });
    }
}

export type TableOfContentSlice = Slice<'table_of_content', never, TableOfContentItem>;

export type TableOfContentItem = {
    title: KeyTextField;
    anker: KeyTextField;
};
