<div class="background-pink my-5 my-lg-6 py-5 py-lg-6" appAos>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 d-flex flex-column text-center">
                <img [attr.data-src]="body.primary.image.url" [alt]="body.primary.image.alt" class="lazyload mx-auto" />
                <p class="color-white text-size-s mt-4 d-none d-lg-block">
                    {{ body.primary.image_caption }}
                </p>
            </div>
            <div class="col-lg-8 ps-lg-6 d-flex align-items-center mt-5 mt-lg-0">
                <div class="text-center text-lg-start">
                    <div [innerHTML]="body.primary.title | renderHtml : htmlSerializer"></div>
                    <div [innerHTML]="body.primary.description | renderHtml"></div>
                    <div class="row">
                        @for (contactAction of body.items; track contactAction) {
                        <div class="col-lg-auto d-flex justify-content-center">
                            <app-link [link]="contactAction.button_link" cssClass="btn btn-black mt-4">
                                {{ contactAction.button_title }}
                            </app-link>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
